import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useRef } from 'react'
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const form = useRef()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 4000);
    return () => clearTimeout(timeoutId);
  }, []);

  const sendEmail = async (e) => { 
    e.preventDefault();

    const result = await emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE, form.current, process.env.REACT_APP_PUBLIC_KEY)
    
    if (result.status === 200) {
      alert('Email sent successfully!')
    } else {
      alert('Email failed to send!')
    }
  }
    return (
        <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
            I am interested in freelance opportunities - especially on ambitious
            or large projects. However, if you have any other requests or
            questions, don't hesitate to contact me using below form either.
          </p>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input placeholder="Name" type="text" name="name" required />
                </li>
                <li className="half">
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="SEND" />
                </li>
              </ul>
            </form>
                </div>
            </div>
            <div className='info-map'>
                Adam Horvitz,
                <br />
                United States,
                <br />
                931 W University Drive, <br />
                Gainesville, FL 32601 <br />
                <span> horvitzadamh@gmail.com </span>
            </div>
            <div className='map-wrap'>
                <MapContainer center ={[29.6521497668837, -82.33479517474119]}zoom={13}>
                    <TileLayer url= "https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Marker position={[29.6521497668837, -82.33479517474119]}>
                        <Popup> Adam lives here, come over for a cup of coffee :) </Popup>
                    </Marker>
                </MapContainer>
            </div>
        </div>
            <Loader type="pacman" />
        </>
    )
}

export default Contact