import { useEffect, useRef } from 'react'
import gsap from 'gsap-trial'
import LogoS from '../../../assets/images/adamheadshot.jpeg'
import './index.scss'

const Logo = () => {
  const solidLogoRef = useRef()

  useEffect(() => {
    gsap.fromTo(
      solidLogoRef.current,
      {
        x: '100vw',  // Start off-screen to the right
        opacity: 0,  // Start with full transparency
      },
      {
        x: 0,  // Slide into the correct position
        opacity: 1,  // Fade in to full opacity
        duration: 2,  // Animation duration
        ease: 'power2.out',  // Smooth easing
      }
    );
  }, []);  // Empty dependency array to ensure it runs only once after mounting
  
  

  return (
    <div className="logo-container">
      <img
        className="solid-logo"
        ref={solidLogoRef}
        src={LogoS}
        alt="JavaScript, Developer"
      />
    </div>
  )
}

export default Logo
